
























































import {Component, Vue} from 'vue-property-decorator';
import CompanyInfoForm from '@/components/business/CompanyInfoForm.vue';
import {businessStore} from '@/store/modules/business/businessStore';
import {applicationStore} from '@/store/modules/application';
import Notifications from '@/components/mixins/Notifications';
import ContainerCalc from '@/components/mixins/ContainerCalc';
import {mixins} from "vue-class-component";
import {storage} from '@/plugins/firebase.init';
import {ref, uploadBytes, getDownloadURL} from 'firebase/storage';
import { doc, updateDoc, deleteField } from 'firebase/firestore';
import {business} from '@/data/firebase';
import { PhotoUrl } from '@/domain/model/types';

@Component({
  name: 'CompanyProfile',
  components: {CompanyInfoForm}
})
export default class CompanyProfile extends mixins(Notifications, ContainerCalc) {

  file: File | null = null;
  url: String | null = null;
  dragging = false;
  lodaing = false

  get busy() {
    return businessStore.saving
  }

  get business() {
    return applicationStore.business;
  }

  get companyLogoUrl() {
    return applicationStore.business?.companyLogoUrl;
  }

  async onUpdateCompanyInfo(payload: any) {
    if (await businessStore.updateCompanyInfo(payload)) {
        await applicationStore.loadBusiness()
    }
  }

  updated() {
    this.calcHeight(this.$refs.container);
  }

  mounted() {
    this.calcHeight(this.$refs.container);
    this.url = this.companyLogoUrl?.normal ?? null;
  }

  //Drag & drop logp
  onChange(e) {
      var files = e.target.files || e.dataTransfer.files;
      
      if (!files.length) {
        this.dragging = false;
        return;
      }
      
      this.createFile(files[0]);
    }

  async createFile(file) {
    if (!file.type.match('jpeg.*|png.*|svg.*')) {
      this.showIssue("Invalid file format")
      this.dragging = false;
      return;
    }
    
    if (file.size > 5000000) {
      this.showIssue("File size exceeded")
      this.dragging = false;
      return;
    }
    
    this.file = file;
    const uploadData = {
      imageFile: this.file
    };
    this.lodaing = true
    const url = `businesses/${this.business!.id}/companyLogo/${uploadData.imageFile!.name}`;
    const imagesRef = ref(storage, url);
    const uploadRef = await uploadBytes(imagesRef, uploadData.imageFile!)
    const LogoUrl = await getDownloadURL(uploadRef.ref);
    const companyLogoURL = await this.getLogoUrl(LogoUrl)

    const payload = {
      companyLogoUrl : companyLogoURL
    }

    this.onUpdateCompanyInfo(payload).then(()=>{
      this.showInfo("Company Logo added.")
      this.url = payload.companyLogoUrl.thumbnail
      this.lodaing = false
    })

    this.dragging = false;
  }

  async removeFile() {
    this.file = null;
    this.url = null;

    const payload = {
      "companyLogoUrl": deleteField(),
    }
    this.onUpdateCompanyInfo(payload).then(()=>{
      applicationStore.clearCompanyLogo()
      this.showInfo("Company Logo removed.")
    })
  }

  onUrlError() {
    this.showIssue("Logo can't be loaded.")
  }

  async getLogoUrl(imageUrl: string): Promise<PhotoUrl> {
    
    let photoUrl;

    if(imageUrl){
      const downloadUrl = imageUrl.replace('gs://','https://storage.googleapis.com/');
      const imgThumbnail = downloadUrl.replace('/companyLogo/','/companyLogo/thumb_');
      const imgNormal = downloadUrl.replace('/companyLogo/','/companyLogo/thumb_');    
      photoUrl = {thumbnail: imgThumbnail, normal: imgNormal};
    }
    
    return photoUrl;
  }
}
