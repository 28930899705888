















































































































































import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import {applicationStore} from '@/store/modules/application';
import {Business} from '@/domain/model/types';
import {auth} from '@/plugins/firebase.init';
import ToolTip from '@/components/custom/ToolTip.vue';
import {mixins} from "vue-class-component";
import TagManager from "@/mixins/TagManager";

@Component({
  name: 'company-info-form',
  components: {
    ToolTip
  }
})
export default class CompanyInfoForm extends mixins(TagManager) {
  @Prop({default: false}) companySetup?: boolean
  @Prop({default: false}) disabled?: boolean
  employeeErrorShow: boolean = false;
  companyInfo: Business = {
    street: '',
    city: '',
    state: '',
    country: '',
    zipCode: '',
    address: '',
    businessCategory: null,
    email: '',
    phone: '',
    website: '',
    name: '',
    employees: '',
    taxId: ''
  };
  employees: any[] = [
    {
      title: '1-10',
      selected: true,
      class: {
        borderRadius: '3px 0 0 3px',
        borderWidth: '1px 0.5px 1px 1px',
        borderStyle: 'solid'
      }
    },
    {
      title: '10-50',
      selected: false,
      class: {
        borderRadius: '0',
        borderWidth: '1px 0.5px 1px 0.5px',
        borderStyle: 'solid'
      }
    },
    {
      title: '50-250',
      selected: false,
      class: {
        borderRadius: '0',
        borderWidth: '1px 0.5px 1px 0.5px',
        borderStyle: 'solid'
      }
    },
    {
      title: '250+',
      selected: false,
      class: {
        borderRadius: '0 3px 3px 0',
        borderWidth: '1px 1px 1px 0.5px',
        borderStyle: 'solid'
      }
    }
  ];

  get business() {
    return applicationStore.business;
  }

  get categories() {
    return applicationStore.businessCategories
  }

  get actionTitle() {
    return this.companySetup ? 'Continue' : 'Update'
  }

  get readOnlyName() {
    const currentUser = auth.currentUser;
    return !(this.$router.currentRoute.name === 'get-started' && currentUser?.providerData?.some((item) => item?.providerId === 'google.com'));

  }

  get hint() {
    return 'Providing your tax ID allows your company<br>' +
        'to be part of the Pigeon directory faster so you can start<br>helping your customers sooner.<br>If you do not provide your tax ID number<br>' +
        ' a manual verification will be required.'
  }

  public onEmployeeClicked(employee) {
    this.pushDataLayout('number_of_employee_activated')
    this.employeeErrorShow = false;
    this.employees.forEach((item) => item.selected = item.title === employee.title);
  }

  public onAction() {
    this.pushDataLayout('company_continue_button_activated')
    this.pushFacebookPixelLead()
    const form: any = this.$refs.formCompanyInfo;
    const employee = this.employees.find((item) => item.selected);
    if (!employee) {
      this.employeeErrorShow = true;
    } else {
      this.employeeErrorShow = false;
    }
    if (!form.validate() || !employee) {
      return;
    }
    const payload = {...this.companyInfo, employees: employee.title};
    payload.phone = payload.phone || null;
    payload.address = `${payload.street}, ${payload.city},`
    if (payload.country) {
      payload.address = `${payload.address} ${payload.country}, ${payload.state} ${payload.zipCode}`
    } else {
      payload.address = `${payload.address} ${payload.state} ${payload.zipCode}`
    }
    if (this.companySetup) {
      //save info
      // await businessStore.saveCompanyInfo(payload)
      this.$emit('on-save', payload)
      return
    }
    // update info
    // await businessStore.updateCompanyInfo(payload)
    this.$emit('on-update', payload)
  }

  public created() {
    if (this.business) {
      this.companyInfo.name = this.business.name;
      this.companyInfo.businessCategory = this.business.businessCategory;
      if (this.business.employees) {
        this.companyInfo.employees = this.business.employees;
      } else {
        this.companyInfo.employees = '1-10';
      }
      this.companyInfo.website = this.business.website;
      this.companyInfo.email = this.business.email;
      this.companyInfo.phone = this.business.phone;
      this.companyInfo.address = this.business.address;
      this.companyInfo.street = this.business.street;
      this.companyInfo.city = this.business.city;
      this.companyInfo.state = this.business.state;
      this.companyInfo.country = this.business.country;
      this.companyInfo.zipCode = this.business.zipCode;
      this.companyInfo.taxId = this.business.taxId;
      this.employees.forEach((item) => item.selected = item.title === this.companyInfo.employees);
    }
  }

  @Watch('companyInfo')
  onCompanyInfoChanged() {
    this.employeeErrorShow = false;
  }
}
